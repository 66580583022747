<template>
  <div>
    <div class="sub-account-detail-wrap">
      <h2 class="page-title">
        <span class="goToBack" @click="goBack">
          <i class="el-icon-back"></i>
          <span>返回子账号列表</span>
        </span>
      </h2>
      <b-container fluid>
        <div class="sub-account-info">
          <div class="left-wrap">
            <div class="sub-account-avatar">
              <img src="@/../public/icon/user-default-avatar.jpg" alt="">
            </div>
          </div>
          <div class="right-wrap">
            <div class="item-wrap">
              <label>用户账号：</label>
              <div class="item">13751081328</div>
            </div>
            <div class="item-wrap">
              <label>用户级别：</label>
              <div class="item">普通会员</div>
            </div>
            <div class="item-wrap">
              <label for="user-name">用户姓名：</label>
              <div class="item">
                <div class="inp-wrap">
                  <input type="text" name="userName" id="user-name" value="席散忆长留" />
                </div>
              </div>
              <div class="change-btn">修改</div>
            </div>
            <div class="item-wrap">
              <label for="discount">折扣设置：</label>
              <div class="item">
                <div class="inp-wrap">
                  <input type="text" name="discount" id="discount" value="0.45" />
                  <span class="tip">(0.45~1)</span>
                </div>
              </div>
              <div class="change-btn">修改</div>
            </div>
            <div class="item-wrap option-btn">
              <div class="option-btn-wrap">
                <label>可用功能：</label>
                <div class="item">
                  <div class="opt-btn">所有</div>
                  <div class="opt-btn active">价格查看</div>
                  <div class="opt-btn">在线交易</div>
                  <div class="opt-btn active">订单导出</div>
                  <div class="opt-btn">在线沟通</div>
                  <div class="opt-btn active">模型下载</div>
                  <div class="opt-btn active">优选品牌</div>
                  <div class="opt-btn active">严选软装</div>
                  <!-- <div class="opt-btn">720°VR设计</div> -->
                  <div class="opt-btn active">e配搭</div>
                </div>
              </div>
              <div class="change-btn">修改</div>
            </div>
            <div class="item-wrap option-btn">
              <div class="option-btn-wrap">
                <label>可看风格：</label>
                <div class="item">
                  <div class="opt-btn active">所有</div>
                  <div class="opt-btn">极简</div>
                  <div class="opt-btn">轻奢</div>
                  <div class="opt-btn">中式</div>
                  <div class="opt-btn">美式</div>
                  <div class="opt-btn">北欧/现代</div>
                  <div class="opt-btn">英式</div>
                  <div class="opt-btn">欧式/新古典</div>
                  <div class="opt-btn">床垫</div>
                  <div class="opt-btn">户外</div>
                  <div class="opt-btn">儿童</div>
                </div>
              </div>
              <div class="change-btn">修改</div>
            </div>
            <div class="item-wrap option-btn">
              <div class="option-btn-wrap">
                <label>可看品牌：</label>
                <div class="item">
                  <div class="opt-btn active">所有</div>
                  <div class="opt-btn">梵恰</div>
                  <div class="opt-btn">佑家·印象</div>
                  <div class="opt-btn">伊信家具</div>
                  <div class="opt-btn">池希家具</div>
                  <div class="opt-btn">凤凰天</div>
                  <div class="opt-btn">名创优品</div>
                  <div class="opt-btn">雅可</div>
                  <div class="opt-btn">大瀛·雅蕾斯</div>
                  <div class="opt-btn">联都</div>
                  <div class="opt-btn">光织物·贝兰特</div>
                  
                </div>
              </div>
              <div class="change-btn">修改</div>
            </div>
          </div>
        </div>
        <div class="sub-account-inner">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <div class="select-items">
              <el-form :inline="true" ref="form" :model="form" label-width="80px">
                <el-form-item label="日期范围:">
                  <el-date-picker
                    v-model="form.startDate"
                    type="date"
                    placeholder="开始日期"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd">
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-date-picker
                    v-model="form.endDate"
                    type="date"
                    placeholder="结束日期"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="关键词:">
                  <el-input v-model="form.keywords" placeholder="订单号"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button class="checked">查询</el-button>
                </el-form-item>
                <el-form-item label="交易类别:">
                  <el-select v-model="form.transactionType">
                    <el-option
                      v-for="item in form.transactionTypeOptions"
                      :key="item"
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <el-tab-pane label="所有流水" name="first">
              <div class="table-wrap">
                <table>
                  <thead>
                    <tr>
                      <th>交易时间</th>
                      <th>交易类别</th>
                      <th>交易金额</th>
                      <th>账户剩余</th>
                      <th>订单</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="item time">2021-08-31 17:39</div>
                      </td>
                      <td>
                        <div class="item">加盟分成</div>
                      </td>
                      <td>
                        <div class="item">6866.00 ¥</div>
                      </td>
                      <td>
                        <div class="item">47616.00 ¥</div>
                      </td>
                      <td>
                        <div class="item order">子账号【Aurora】升级高级会员，确认加盟分成利润入账[子订单号=MYD2021083012345，入账金额6866.00元]</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="item time">2021-08-31 17:39</div>
                      </td>
                      <td>
                        <div class="item">订单支付</div>
                      </td>
                      <td>
                        <div class="item deficit">-10808.00 ¥</div>
                      </td>
                      <td>
                        <div class="item">28000.00 ¥</div>
                      </td>
                      <td>
                        <div class="item order">订单支付[父订单号=MYD2021083012345]</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="支出明细" name="second">
              <div class="table-wrap">
                <table>
                  <thead>
                    <tr>
                      <th>交易时间</th>
                      <th>交易类别</th>
                      <th>交易金额</th>
                      <th>账户剩余</th>
                      <th>订单</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="item time">2021-08-31 17:39</div>
                      </td>
                      <td>
                        <div class="item">订单支付</div>
                      </td>
                      <td>
                        <div class="item deficit">-10808.00 ¥</div>
                      </td>
                      <td>
                        <div class="item">28000.00 ¥</div>
                      </td>
                      <td>
                        <div class="item order">订单支付[父订单号=MYD2021083012345]</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="收入明细" name="third">
              <div class="table-wrap">
                <table>
                  <thead>
                    <tr>
                      <th>交易时间</th>
                      <th>交易类别</th>
                      <th>交易金额</th>
                      <th>账户剩余</th>
                      <th>订单</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="item time">2021-08-31 17:39</div>
                      </td>
                      <td>
                        <div class="item">加盟分成</div>
                      </td>
                      <td>
                        <div class="item">6866.00 ¥</div>
                      </td>
                      <td>
                        <div class="item">47616.00 ¥</div>
                      </td>
                      <td>
                        <div class="item order">子账号【Aurora】升级高级会员，确认加盟分成利润入账[子订单号=MYD2021083012345，入账金额6866.00元]</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </el-tab-pane>
          </el-tabs>
          <div class="paging-wrap">
            <div class="paging-con">
              <el-pagination
                background
                layout="prev, pager, next, jumper"
                :pager-count="5"
                prev-text="上一页"
                next-text="下一页"
                :total="100">
              </el-pagination>
            </div>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
  import dump from "@/util/dump";
  export default {
    data (){
      return {
        activeName: 'first',
        form: {
          
        }
      }
    },
    methods: {
      // 返回子账号列表
      goBack(){
        dump.link({
          type: 1,
          link: "subAccountList"
        })
      },
      handleClick(tab, event) {
        console.log(tab, event);
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/subAccountManagement/subAccountDetail/subAccountDetail";
</style>
